.restaurant-main-container {
  background: #e5e5e5;
}

.restaurant-container {
  width: 40%;
  background: white;
  margin: auto;
  height: 100vh;
}

.restaurant-container input {
  color: #0c1830 !important;
  border: 2px solid #c7c7c7;
  line-height: 3;
  height: 44px;
  border-radius: 5px;
  color: black;
}

.restaurant-container input:focus {
  color: black;
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #ff5100 !important;
}

.restaurant-container select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #ff5100 !important;
  color: black;
}

.restaurant-container select {
  color: gray;
  border: 2px solid #c7c7c7;
  height: 44px;
  border-radius: 5px;
  margin-inline-end: 2%;
}

.main-status-div {
  display: flex;
  justify-content: space-evenly;
  padding: 5% 3% 0 0%;
}

.status-div {
  display: inline-block;
}

.status-div img {
  margin-inline-end: 10px;
}

.hr-horizontal {
  margin-bottom: 6%;
  margin-top: 4%;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
}

.restaurant-doc-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: -2% 0% 4% 0%;
}

.status-title {
  display: flex;
  align-items: flex-start;
}

.docs-list-div {
  padding: 0% 6% 0 6%;
}

.rejected label {
  border: 2px solid #e0393b !important;
  background: rgba(224, 57, 59, 0.3);
}

.approved label {
  border: 2px solid #8fe139 !important;
  background: rgba(143, 225, 57, 0.3);
}

.waiting label {
  border: 2px solid #e09b39 !important;
  background: rgba(224, 155, 57, 0.3);
}

.doc-info-section {
  margin-bottom: 25px;
}

.doc-info-section label {
  margin-bottom: 0;
  border: 1px solid darkgrey;
  border-radius: 5px;
  width: 100%;
  padding: 1.8% 1.5% 1.5% 3.5%;
  font-size: 18px;
  font-weight: 600;
}

.doc-info-section label:hover {
  cursor: pointer;
}

.doc-info-section i {
  float: right;
  line-height: unset;
  margin-right: 10px;
}

.logout-btn-div {
  display: flex;
  justify-content: space-between;
  padding: 3% 6% 150px 6%;
}

.logout-btn-div button {
  width: 215px !important;
}

.logout-button-div button {
  background: #ff5100;
  color: #ffff;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}

.logout-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}

.logout-button-div button:focus {
  outline: none !important;
  outline: 0;
}

.final-button-div {
  text-align: center;
}

.final-button-div button {
  background: #ff5100;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}

.final-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}

.final-button-div button:focus {
  outline: none !important;
  outline: 0;
}

.confirm-button-div {
  text-align: center;
}

.confirm-button-div button {
  background: #ff5100;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  margin: 20px 0 30px 0px;
}

.confirm-button-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}

.confirm-button-div button:focus {
  outline: none !important;
  outline: 0;
}

.bank-statement-div label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3%;
}

.document-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49.18px;
  margin: 2% 0% 4% 0%;
}

.bank-input-div {
  margin-top: 30px;
}

.choosen-doc-div {
  padding: 5% 6% 0% 6%;
}

.return-btn {
  text-align: center;
  padding-bottom: 150px;
}

.return-btn button {
  background: white;
  color: #c7c7c7;
  border: 2px solid #c7c7c7;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}

.return-btn button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}

.return-btn button:focus {
  outline: none !important;
  outline: 0;
}

.info-list span {
  font-weight: 600;
}

.info-list {
  font-size: 16px;
}

.upload-doc-btn-div {
  justify-content: center;
  display: flex;
}

.upload-doc-btn-div input {
  display: none;
  color: black;
}

.upload-doc-btn-div img {
  padding: 5px 0px 10px 10px;
}

.upload-label {
  display: inline-block !important;
  cursor: pointer !important;
  text-decoration: underline;
  font-size: 15px;
  color: #ff5100;
  text-decoration: none;
  border: 2px dashed #ff5100;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 20px 2px 20px;
  white-space: nowrap;
  width: fit-content;
}

.upload-label:hover {
  cursor: pointer;
}

.document-image-div {
  text-align: center;
  margin-block: 5%;
}

.document-image-div img {
  width: 180px;
  height: 110px;
}

.document-image {
  text-align: center;
  margin-block: 8%;
}

.document-image img {
  height: 130px;
}

.invalid-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90% !important;
  color: #dc3545;
  margin-bottom: 0 !important;
}

.document-icon-div {
  display: flex;
  align-items: baseline;
  margin: 25px 0 25px 0;
  justify-content: center;
}

.document-icon-div i {
  font-size: 65px;
  color: #ff5100;
  background: white;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 45%;
}

.document-icon-div span {
  font-weight: 600;
  margin-left: 10px;
  display: inline-block;
  height: 1.5em;
  overflow: hidden;
  color: #FF5100;
}

.document-icon-div span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.svg-icon-div {
  text-align: center;
  margin-bottom: 25px;
}

.svg-icon-div span {
  font-weight: 600;
  display: inline-block;
  height: 1.5em;
  overflow: hidden;
}

.logout-bttn-div {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

/* modal */

.modal-info {
  border-radius: 10px;
  position: absolute;
}

.modal-dialog {
  width: 400px;
  top: 35%;
  margin: 0 auto;
}

.modal-content {
  border-radius: 10px;
}

.modal-body {
  position: relative;
  padding: 25px 40px 30px 40px;
}

.logout-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin-block: 2%;
}

.logout-content-label {
  margin-top: 10px;
  margin-bottom: 30px;
}

.no-btn {
  line-height: 0;
  background: #cccccc;
  color: white;
  border: none;
  border-radius: 30px;
  width: 100px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}

.no-btn:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}

.no-btn:focus {
  outline: none !important;
  outline: 0;
}

.yes-btn {
  line-height: 0;
  color: white;
  background: #ff5100;
  border: none;
  border-radius: 30px;
  width: 100px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}

.yes-btn:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}

.yes-btn:focus {
  outline: none !important;
  outline: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.fade {
  opacity: 1;
  transition: opacity 0.15s linear;
}

@media only screen and (min-width: 240px) and (max-width: 498px) {
  .modal-dialog {
    width: 300px;
  }

  .logout-content-label {
    font-size: 12px;
  }

  .logout-label {
    font-size: 18px;
  }

  .modal-body {
    padding: 25px;
  }

  .restaurant-container {
    width: 90%;
  }

  .confirm-button-div button {
    width: 225px;
  }

  .return-btn button {
    width: 225px;
  }

  .restaurant-doc-title {
    font-size: 20px;
  }

  .document-title {
    font-size: 25px;
  }

  .bank-title {
    margin: 2% 0% -5% 0%;
  }

  .status-div label {
    font-size: 12px;
  }

  .logout-btn-div button {
    width: 120px !important;
  }

  .final-button-div button {
    width: 200px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .restaurant-container {
    width: 70%;
  }

  .restaurant-doc-title {
    font-size: 25px;
  }

  .document-title {
    font-size: 27px;
  }

  .bank-title {
    margin: 2% 0% -5% 0%;
  }

  .logout-btn-div button {
    width: 155px !important;
  }

  .final-button-div button {
    width: 200px;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1238px) {
  .restaurant-container {
    width: 60%;
  }

  .restaurant-doc-title {
    font-size: 25px;
  }

  .document-title {
    font-size: 30px;
  }

  .bank-title {
    margin: 2% 0% -5% 0%;
  }

  .logout-btn-div button {
    width: 200px !important;
  }

  .final-button-div button {
    width: 200px;
  }
}
