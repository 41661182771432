@font-face {
  font-family: "Avenir Next";
  src: url(https://pre-api.vooyapp.com/default_attachments/font/AvenirNext-Regular.ttf);
}
body {
  font-family: "Avenir Next" !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90% !important;
  color: #dc3545;
}
.is-invalid {
  border-color: #dc3545 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
