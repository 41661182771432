.header-partner-div {
  background: #141a30;
  text-align: start;
  padding-inline-start: 7%;
}
.vooy-food-svg  {
  width: 127px;
  height: 72px;
  margin: 7px 0px;
  object-fit: contain;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .header-partner-div {
    text-align: center !important;
    /* padding-block: 10px !important; */
    padding-inline-start: 0 !important;
  }
  .vooy-food-svg  {
    width: 127px;
    height: 72px;
    object-fit: contain;
  }
}
