.header-btn-div button {
  background: #FF5100;
  color: white;
  border: none;
  border-radius: 30px;
  width: 165px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.header-btn-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.header-btn-div button:focus {
  outline: none !important;
  outline: 0;
}
.header-auth-div {
  background: #141a30;
  padding: 0 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .header-btn-div button {
    width: 100px;
  }
  .header-auth-div {
    padding: 0% 7%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .header-btn-div button {
    width: 140px;
  }
  .header-auth-div {
    /* padding: 2% 7%; */
  }
}
