.login-link-div span b {
  color: #FF5100;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.login-link-div span b:hover {
  cursor: pointer;
  text-decoration: underline;
}
.margin-b-pass {
  margin-bottom: 0.5rem;
}
