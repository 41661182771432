.personal-main-div {
  background: #e5e5e5;
}
.already-account-div {
  text-align: center;
  padding-bottom: 50px;
}
.already-account-div label {
  color: #ff5100;
  font-weight: 600;
}
.already-account-div label:hover {
  text-decoration: underline;
  cursor: pointer;
}
.mobile-no-div select {
  width: fit-content;
  color: gray;
}
.personal-container {
  width: 40%;
  background: white;
  margin: auto;
  padding: 2% 2% 0 2%;
  /* height: 100vh; */
}
.personal-container input {
  color: #0c1830 !important;
  border: 2px solid #c7c7c7;
  line-height: 3;
  height: 44px;
  border-radius: 5px;
  color: black;
}
.personal-container input:focus {
  color: black;
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #ff5100 !important;
}
.personal-container select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #ff5100 !important;
  color: black;
}
.personal-container select {
  color: gray;
  border: 2px solid #c7c7c7;
  height: 44px;
  border-radius: 5px;
  margin-inline-end: 2%;
}
/* .name-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 24.59px;
} */
.pass-m-div {
  margin-bottom: 1% !important;
}
.pass-div {
  position: relative;
  margin-bottom: 2.5rem;
}
.pass-div input {
  position: relative;
  color: black;
}
.pass-div i {
  position: absolute;
  padding: 10px;
  top: 5px;
  right: 15px;
  color: #796d70;
}
.pass-div i:hover {
  cursor: pointer;
}
.confirm-pass-div {
  position: relative;
  margin-bottom: 2.5rem;
}
.confirm-pass-div input {
  position: relative;
  color: black;
}
.confirm-pass-div i {
  position: absolute;
  padding: 10px;
  top: 5px;
  right: 15px;
  color: #796d70;
}
.confirm-pass-div i:hover {
  cursor: pointer;
}
.t-and-c-div {
  display: flex;
}
.t-and-c-div a {
  color: #ff5100;
  font-weight: 600;
}
.t-and-c-div a:hover {
  color: #ff5100;
}
.t-and-c-div input {
  width: 1.3em !important;
  height: 1.3em !important;
  border-radius: 50%;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  background: #e6e6e6;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  position: relative !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 2%;
}
.t-and-c-div input[type="checkbox"]:checked {
  background-color: #ff5100;
  opacity: 1;
  outline: none;
}
.t-and-c-div input[type="checkbox"]:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.t-and-c-div input:hover {
  background-color: #ff5100;
}
.pass-strength-bar img {
  height: 10px;
  margin: 0px 0px 15px 0px;
}
.pass-tooltip-div {
  justify-content: space-between;
  /* padding-block-start: 8px; */
  display: flex;
}
.pass-tooltip-div {
  font-size: 16px;
  padding: 0;
}
.pass-tooltip-div i {
  margin-block: auto;
  color: #FF5100;
}
.pass-tooltip-div i:hover {
  cursor: help;
}
.tooltip-width {
  width: 37%;
}
.disable-btn button {
  background: #cccccc !important;
  pointer-events: none;
}
.submit-btn-div {
  padding-top: 25px;
  text-align: center;
}
.pad-btm {
  padding-bottom: 35px !important;
}
.submit-btn-div button {
  background: #ff5100;
  color: white;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.submit-btn-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.submit-btn-div button:focus {
  outline: none !important;
  outline: 0;
}
.last-name-div {
  display: flex;
  justify-content: space-between;
}
.father-last-name {
  width: 90%;
}
.mother-last-name {
  width: 90%;
  margin-inline-end: 10px;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .personal-container {
    width: 90%;
  }
  .last-name-div {
    display: block;
  }
  .mother-last-name {
    width: 100%;
    margin-inline-end: 0px;
  }
  .father-last-name {
    width: 100%;
  }
  .submit-btn-div button {
    width: 200px;
  }
  .personal-container input {
    font-size: 13px;
  }
  .personal-container select {
    font-size: 13px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .personal-container {
    width: 70%;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1065px) {
  .personal-container {
    width: 60%;
  }
}
