.lodaing-label-layout-div {
  text-align: center;
  margin-top: 10%;
}

.upload-regime-div input {
  display: none;
}
.upload-regime-div {
  display: grid;
  margin-bottom: 2rem;
}
.res-doc-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(20, 26, 48, 0.6);
  margin-bottom: 1rem;
}
.legal-tooltip {
  margin-left: 20px;
  font-size: 18px;
  cursor: help;
}
.legal-tooltip div {
  padding: 0;
}
.css-1s2u09g-control {
  /* font-size: 14px !important; */
  color: #0c1830 !important;
  height: 44px !important;
  border-radius: 5px !important;
  border: 2px solid #c7c7c7 !important;
}
.css-1pahdxg-control:hover {
  height: 44px !important;
  border-radius: 5px !important;
  border-color: unset !important;
  border-width: 0 !important;
  border: 2px solid #ff5100 !important;
  box-shadow: 0 0 0 0 white !important;
}
.css-1pahdxg-control {
  height: 44px !important;
  border-radius: 5px !important;
  border-color: unset !important;
  border-width: 0 !important;
  border: 2px solid #ff5100 !important;
  box-shadow: 0 0 0 0 white !important;
}
.css-1pahdxg-control:focus {
  height: 44px !important;
  border-radius: 5px !important;
  border-color: unset !important;
  border-width: 0 !important;
  border: 2px solid #ff5100 !important;
  box-shadow: 0 0 0 0 white !important;
}
.css-319lph-ValueContainer {
  height: 40px !important;
  top: -3px !important;
  padding: 2px 10px !important;
}
.css-g1d714-ValueContainer {
  height: 40px !important;
  top: -4px !important;
}
.css-1hb7zxy-IndicatorsContainer {
  height: 40px !important;
}
/* .css-12jo7m5 {
  background: #FF5100 !important;
} */
.css-1wy0on6 {
  height: 40px !important;
}
.css-1d8n9bt {
  top: -4px !important;
}
.css-1hwfws3 {
  top: -4px !important;
  flex-wrap: nowrap !important;
}
.css-1insrsq-control {
  height: 44px !important;
  border: 2px solid #c7c7c7 !important;
}
.upload-regime-div img {
  height: 110px;
  width: 160px;
  margin-bottom: 3rem;
  cursor: pointer;
}
.file-image-div {
  display: grid;
}

.upload-regime-title svg {
  padding-left: 10px;
}
.upload-regime-title {
  display: flex;
  cursor: pointer !important;
  font-size: 14px !important;
  color: #ff5100;
  text-decoration: none;
  border: 2px dashed #ff5100;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 7px 15px 7px 15px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 700 !important;
}
.res-no-div {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  line-height: 20px;
}
.res-no-div select {
  width: -moz-fit-content;
  width: fit-content;
  color: gray;
}
.legal-info {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 2.5rem;
}
.upload-regime-title:hover {
  cursor: pointer;
}
.search-address-div {
  position: relative;
}
.search-address-div input {
  /* padding: 0.375rem 4.75rem 0.375rem 0.75rem; */
  padding: 6px 45px 6px 12px;
}
.search-address-div i {
  color: red;
  font-size: 22px;
  font-weight: 600;
  right: 15px !important;
  top: 11px !important;
  padding: 5px 5px 0px 3px !important;
  position: absolute;
  line-height: 0.6;
}
.search-address-div i:hover {
  background-color: red;
  transform: translateX(-2px);
  color: white;
  width: 22px;
  text-align: center;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 600;
}
.language-input-box {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#restaurantAddress::placeholder {
  color: grey !important;
}
.language-input-box:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.animated {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.loader {
  -webkit-animation-name: horizontalProgressBar;
  animation-name: horizontalProgressBar;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  /* background: #FF5100; */
  background: #ff5100;
  margin-top: 0.5rem;
  height: 7px;
  left: 0;
  top: 0;
  width: 0%;
  z-index: 9999;
  position: relative;
  border-radius: 5px;

  /* background-image: url("../../asset//VooyLoader.gif");
  content: "";
  position: fixed;
  top: 30%;
  font-size: 1.5rem;
  height: 50px;
  width: 50px;
  background-size: 100% 100%;
  z-index: 1070; */
}

.loader:after {
  display: block;
  position: absolute;
  content: "";
  right: 0px;
  width: 100px;
  height: 100%;
  /* box-shadow: #ef534e 1px 0 6px 1px; */
  opacity: 0.5;
}
@keyframes horizontalProgressBar {
  0% {
    width: 30%;
  }
  20% {
    width: 45%;
  }
  30% {
    width: 50%;
  }
  40% {
    width: 75%;
  }
  50% {
    width: 80%;
  }
  60% {
    width: 90%;
  }
  100% {
    width: 100%;
  }
}
.tooltip-image-width {
  background: none !important;
}
.places-suggestion-div hr {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.note-info-label {
  font-size: 14px;
  display: block;
  font-weight: 500;
  line-height: 18px;
  margin-block: 15px;
}
.info-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #141a30;
  margin-top: 10px;
}
.input-sub-div {
  margin-bottom: 2.5rem;
}
.legal-id-div {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
.personal-main-div {
  background: #e5e5e5;
}
.personal-info-container {
  width: 40%;
  background: white;
  margin: auto;
  padding: 2% 2% 0 2%;
}
.personal-info-container input {
  color: #0c1830 !important;
  border: 2px solid #c7c7c7;
  line-height: 3;
  height: 44px;
  border-radius: 5px;
  color: black;
}
.personal-info-container input:focus {
  color: black;
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #ff5100 !important;
}
.personal-info-container select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
  border: 2px solid #ff5100 !important;
  color: black;
}
.personal-info-container select {
  color: gray;
  border: 2px solid #c7c7c7;
  height: 44px;
  border-radius: 5px;
  margin-inline-end: 2%;
  padding: 6px 8px !important;
}
.disable-btn button {
  background: #cccccc !important;
  pointer-events: none;
}
.submit-btn-div {
  padding-top: 25px;
  padding-bottom: 50px;
  text-align: center;
}
.red-remark {
  color: red;
  margin-bottom: 5px;
}
.address-remark {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}
/* .custom-multi-select {
  border: 2px solid #c7c7c7;
  border-radius: 5px;
} */
.regime-img {
  margin-bottom: 0.5rem !important;
}
.regime-img img {
  margin-bottom: 0.5rem !important;
}
.file-image-div i {
  font-size: 65px;
  color: #ff5100;
  background: white;
  width: fit-content;
  border-radius: 45%;
  margin-bottom: 3rem;
}
.pdf-icon-div {
  display: flex;
  align-items: baseline;
}
.pdf-icon-div span {
  font-weight: 600;
  margin-left: 10px;
  display: inline-block;
  height: 1.5em;
  overflow: hidden;
  color: #ff5100;
}
.pdf-icon-div span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.disable-search input {
  cursor: not-allowed !important;
  background-color: #eee !important;
}
.fa-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
.submit-btn-div button {
  background: #ff5100;
  border: none;
  border-radius: 30px;
  width: 315px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
}
.submit-btn-div button:hover {
  color: black;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.submit-btn-div button:focus {
  outline: none !important;
  outline: 0;
}
.tooltip-icon {
  color: #ff5100;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .tooltip-image-width img {
    width: 275px;
  }
  .personal-info-container {
    width: 90%;
  }
  .css-g1d714-ValueContainer {
    overflow: auto !important;
  }
  .submit-btn-div button {
    width: 200px;
  }
  .personal-info-container input {
    font-size: 13px;
  }
  .personal-info-container select {
    font-size: 13px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 776px) {
  .personal-info-container {
    width: 70%;
  }
  .css-g1d714-ValueContainer {
    overflow: auto !important;
  }
}

@media only screen and (min-width: 777px) and (max-width: 1065px) {
  .personal-info-container {
    width: 60%;
  }
}
